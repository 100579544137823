import { createUseStyles } from 'react-jss';
import { breakpoints } from '../../theme';
import curveVector from '../../assets/vectors/about-curve.svg';
import flipCurveVector from '../../assets/vectors/about-curve-flip.svg';

const listItemHeight = 54;

export const useStyles = createUseStyles({
  root: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    minHeight: 845,
    paddingTop: 50,
    overflow: 'hidden',

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 50,
      left: (props) => (props.ltr ? 0 : '100%'),
      width: '100%',
      height: 'calc(100% + 50px)',
      backgroundImage: (props) =>
        `url('${props.ltr ? flipCurveVector : curveVector}')`,
      backgroundSize: 'auto calc(100% - 100px)',
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: (props) => (props.ltr ? 'right' : 'left'),
      transform: (props) =>
        props.ltr ? 'translateX(-40%)' : 'translateX(-55%)',
    },
  },
  row: {
    flexDirection: (props) => (props.ltr ? 'row-reverse' : 'row'),
  },
  list: {
    textAlign: (props) => (props.ltr ? 'left' : 'right'),
  },
  listItem: {
    color: 'white',
    display: 'flex',
    maxWidth: 364,
    padding: '12px 24px',
    margin: '36px 0',
    marginLeft: (props) => (props.ltr ? 0 : 'auto'),
    marginRight: (props) => (props.ltr ? 'auto' : 0),
    direction: (props) => (props.ltr ? 'ltr' : 'rtl'),
  },
  listItemImageWrapper: {
    position: 'relative',
    width: listItemHeight,
    height: listItemHeight,
    marginLeft: (props) => (props.ltr ? 0 : 24),
    marginRight: (props) => (props.ltr ? 24 : 0),

    '& img': {
      position: 'absolute',
      top: 'calc(50% + 5px)',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  listItemTextWrapper: {
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 238,
    maxHeight: listItemHeight,
  },
  listItemLabel: {
    fontSize: (props) => (props.ltr ? 20 : 22),
    lineHeight: 1.55,
    margin: 0,
  },
  listItemInfo: {
    fontSize: 16,
    lineHeight: 1.56,
  },
  mobile: {
    position: 'absolute',
    top: -50,
    left: (props) => (props.ltr ? 0 : 'auto'),
    right: (props) => (props.ltr ? 'auto' : 0),
    width: '45%',
    // maxWidth: '45',
    zIndex: 1,

    '& img': {
      minWidth: 158,
      width: '100%',
    },
  },
  patientCard: {
    position: 'relative',
    marginTop: '14%',
    // marginLeft: (props) => (props.ltr ? 'auto' : 0),
    // marginRight: (props) => (props.ltr ? 0 : 'auto'),

    '& .dot-background': {
      position: 'absolute',
      left: (props) => (props.ltr ? 'auto' : 0),
      right: (props) => (props.ltr ? 0 : 'auto'),
      width: '100%',
      height: '23vh',
      zIndex: 0,
    },

    '& img': {
      position: 'relative',
      maxWidth: '75%',
      margin: (props) => (props.ltr ? '0 100px 0 auto' : '0 100px 0 auto'),
      display: 'block',
      zIndex: 2,
    },
  },

  '@media (orientation: portrait)': {
    root: {
      height: 'auto',
    },
    row: {
      paddingBottom: 35,
    },
  },

  [breakpoints.xl.down]: {
    root: {
      // backgroundSize: '775px auto',
    },
    listItem: {
      maxWidth: 364,
    },
    mobile: {
      top: -50,
      left: (props) => (props.ltr ? '-1vw' : 'auto'),
      right: (props) => (props.ltr ? 'auto' : '-1vw'),

      '& img': {
        minWidth: 158,
      },
    },
    patientCard: {
      '& img': {
        margin: () => '0 4vh 0 auto',
      },
    },
  },

  [breakpoints.lg.down]: {
    root: {
      height: 845,
      paddingTop: 170,

      '&:before': {
        top: '24vh',
        left: () => 0,
        transform: (props) =>
          props.ltr ? 'translateX(-24px)' : 'translateX(24px)',
        height: 'calc(100% + 100px - 24vh)',
      },
    },
    row: {
      flexDirection: () => 'column-reverse',
    },
    list: {
      marginTop: 138,
    },
    listItem: {
      color: 'white',
      display: 'flex',
      maxWidth: 364,
      padding: 0,
      margin: '24px 0 48px',

      '&:after': {
        display: 'none',
      },
    },
    mobile: {
      top: -170,
      left: (props) => (props.ltr ? 'auto' : 15),
      right: (props) => (props.ltr ? 15 : 'auto'),

      '& img': {
        width: '21vw',
      },
    },
    patientCard: {
      position: 'absolute',
      top: -130,
      left: (props) => (props.ltr ? 'auto' : 85),
      right: (props) => (props.ltr ? 85 : 'auto'),
      width: 350,
      marginTop: 30,
      marginRight: (props) => (props.ltr ? 0 : 'auto'),
      marginLeft: (props) => (props.ltr ? 'auto' : 'auto'),

      '& .dot-background': {
        position: 'absolute',
        left: 0,
        width: '100%',
        height: 105,
      },

      '& img': {
        margin: () => 0,
        width: 'auto',
        maxWidth: '75vh',
        height: '50vw',
        maxHeight: 250,
      },
    },
  },

  [breakpoints.md.down]: {
    root: {
      minHeight: 780,
    },
    listItemLabel: {
      margin: 0,
    },
  },

  [breakpoints.sm.down]: {
    root: {
      minHeight: 0,
      height: 'auto',

      '&:before': {
        top: 130,
        height: 'calc((100% + 100px) - 130px)',
      },
    },
    row: {
      paddingBottom: 30,
    },
    list: {
      marginTop: 170,
    },
    listItem: {
      margin: '24px 0',
    },
    listItemTextWrapper: {
      fontWeight: 500,
      maxWidth: 238,
      marginTop: -10,
    },
    listItemLabel: {
      fontSize: (props) => (props.ltr ? 16 : 18),
    },
    listItemInfo: {
      fontSize: 14,
    },
    listItemImageWrapper: {
      width: 40,
      height: 40,
      marginLeft: 16,

      '& img': {
        width: 70,
      },
    },
    patientCard: {
      position: 'absolute',
      top: -130,
      right: 5,
      width: '70%',
      marginTop: 30,
      marginRight: 'auto',

      '& .dot-background': {
        position: 'absolute',
        left: 0,
        width: '100%',
        height: 105,
      },

      '& img': {
        margin: '0',
        width: 'auto',
        height: '50vw',
        maxHeight: 250,
      },
    },
  },
});
