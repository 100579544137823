import clsx from 'clsx';
import React from 'react';
import { useParams } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import ProductContents from '../../contents/ProductContents';
import { useProductPreviewStyles } from './styles';

const ProductPreview = ({ activeKey, className, ...rest }) => {
  const classes = useProductPreviewStyles();
  const { lang } = useParams();
  const activeItem = ProductContents.products.find(
    (item) => item.key === activeKey
  );

  return (
    <div
      className={clsx('dot-background op-20', className, classes.root)}
      style={{
        backgroundImage: activeItem.imageBg,
        boxShadow: activeItem.boxShadow,
      }}
      {...rest}
    >
      {ProductContents.products.map((product) => (
        <div
          key={product.key}
          className={clsx(classes.imageWrapper, product.icon.position)}
        >
          <Fade opposite when={product.key === activeItem.key}>
            <img
              src={product.icon[lang || 'fa']}
              className={classes.img}
              alt='product preview'
            />
          </Fade>
        </div>
      ))}
    </div>
  );
};

export default ProductPreview;
