import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { Navbar, ListGroup } from 'react-bootstrap';
import { useTheme } from 'theming';
import clsx from 'clsx';
import Fade from 'react-reveal/Fade';
import { breakpoints } from '../../theme';
import HeaderContents from '../../contents/HeaderContents.json';
import logo from '../../assets/vectors/header-logo.svg';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
  },
  toggler: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: 'none',
    backgroundColor: 'transparent',

    '& .icon-bar': {
      display: 'block',
      width: 28,
      borderTop: (props) => `3px solid ${props.theme.dark}`,
      borderRadius: 16,
      transition: 'all .3s',

      '&:nth-child(2)': {
        margin: '7px 0',
        width: 30,
      },

      [breakpoints.sm.down]: {
        width: 21,
        borderTopWidth: 2,

        '&:nth-child(2)': {
          margin: '5px 0',
          width: 22,
        },
      },
    },

    '&.expanded .icon-bar:first-child': {
      transform: 'rotate(-45deg)',
      transformOrigin: 'right',
    },

    '&.expanded .icon-bar:nth-child(2)': {
      opacity: 0,
    },

    '&.expanded .icon-bar:last-child': {
      transform: 'rotate(45deg)',
      transformOrigin: 'right',
    },
  },

  menu: {
    position: 'absolute',
    textAlign: (props) => (props.ltr ? 'left' : 'right'),
    top: 40,
    left: -24,
    width: 'calc(100% + 48px)',
    height: 0,
    padding: '0 62px',
    backgroundColor: 'white',
    overflow: 'hidden',
    transform: 'none',
    transition: 'height 0.3s linear',

    '&.expanded': {
      height: 365,
    },

    '& .dot-background': {
      position: 'absolute',
      top: 40,
      left: 24,
      width: 'calc(100% - 48px)',
      height: 305,
      zIndex: 0,
    },

    '& .react-reveal': {
      position: 'relative',
      display: 'block',
      marginBottom: 32,

      '&:first-child': {
        marginTop: 50,
      },

      '&:before,&:first-child:before,&:last-child:before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        right: (props) => (props.ltr ? 'auto' : -12),
        left: (props) => (props.ltr ? -12 : 'auto'),
        transform: 'translateY(-4px)',
        width: 6,
        height: 6,
        borderRadius: '50%',
        backgroundColor: (props) => props.theme.primary,
        boxShadow: '0 3px 8px 0 rgba(27, 144, 250, 0.72)',
      },
    },
  },
  menuItem: {
    fontSize: 20,
    fontWeight: 'bold',
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent',
  },
  langLink: {
    fontFamily: 'Axiforma',
    fontSize: 17,
    lineHeight: 1.82,
    fontWeight: 600,
    display: 'inline-block',
    position: 'relative',

    '&:before': {
      display: 'none',
    },

    '&:first-child': {
      marginLeft: 21,

      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: -10,
        height: 23,
        width: 0,
        borderLeft: '1px solid',
        borderColor: (props) => props.theme.primary,
      },
    },
  },
});

export default function MobileNav({ expanded, setExpanded }) {
  const theme = useTheme();
  const { lang } = useParams();
  const classes = useStyles({ theme: theme, ltr: lang === 'en' });

  return (
    <Navbar bg='white' className={clsx(classes.root, 'd-lg-none')}>
      <Navbar.Brand className={classes.brand}>
        <img src={logo} alt='synapps logo' height={24} />
      </Navbar.Brand>

      <button
        className={clsx(classes.toggler, expanded && 'expanded')}
        onClick={() => setExpanded(!expanded)}
      >
        <span className='icon-bar' />
        <span className='icon-bar' />
        <span className='icon-bar' />
      </button>

      <div className={clsx(classes.menu, expanded && 'expanded')}>
        <div className='dot-background' />
        <ListGroup>
          <Fade top when={expanded}>
            <ListGroup.Item className={classes.menuItem}>
              <Link
                to='/'
                onClick={() => setExpanded(false)}
                className={classes.langLink}
              >
                Fa
              </Link>
              <Link
                to='/en'
                onClick={() => setExpanded(false)}
                className={classes.langLink}
              >
                En
              </Link>
            </ListGroup.Item>
            {HeaderContents.mobileMenu.map((item, index) => (
              <ListGroup.Item key={index} className={classes.menuItem}>
                <a href={item.href} onClick={() => setExpanded(false)}>
                  {item.label[lang || 'fa']}
                </a>
              </ListGroup.Item>
            ))}
          </Fade>
        </ListGroup>
      </div>
    </Navbar>
  );
}
//   <Navbar.Brand className={classes.brand}>
//     <img src={logo} className={clsx(classes.brandImg)} alt='synapps logo' />
//   </Navbar.Brand>
//   <button
//     className={clsx(classes.toggler, expandMenu && 'expanded')}
//     onClick={() => setExpandMenu(!expandMenu)}
//   >
//     <span className='icon-bar' />
//     <span className='icon-bar' />
//     <span className='icon-bar' />
//   </button>
//   <div className={clsx(classes.menu, expandMenu && 'show')}>
//     <div className='dot-background' />
//     <div className='d-lg-none'>
//       <Fade top when={expandMenu}>
//         <a href={''} onClick={() => setExpandMenu(false)}>
//           {'FA'}
//         </a>
//         {HeaderContents.mobileMenu.map((item, index) => (
//           <a
//             key={index}
//             href={item.href}
//             onClick={() => setExpandMenu(false)}
//           >
//             {item.label}
//           </a>
//         ))}
//       </Fade>
//     </div>
//     <div className='d-none d-lg-flex justify-content-between'>
//       <div className={classes.menuRight}>
//         {HeaderContents.menu.map((item, index) => (
//           <a
//             key={index}
//             href={item.href}
//             onClick={() => setExpandMenu(false)}
//           >d
//             {item.label}
//           </a>
//         ))}
//       </div>
//       <div className={classes.menuLeft}>
//         <a
//           href={HeaderContents.contactUs.href}
//           onClick={() => setExpandMenu(false)}
//         >
//           {HeaderContents.contactUs.label}
//           <img
//             src={phoneVector}
//             alt='contact vector'
//             width={19}
//             className='mr-2'
//           />
//         </a>
//         <Link to='/en' onClick={() => setExpandMenu(false)}>
//           <img
//             src={langVector}
//             alt='language vector'
//             width={24}
//             className='mr-2'
//           />
//         </Link>
//       </div>
//     </div>
//   </div>
