import React from 'react';
import './JumpAndSlide.css';

export default function JumpAndSlide() {
  return (
    <div className='loading-container'>
      <div className='loading-box'></div>
      <div className='loading-box'></div>
      <div className='loading-box'></div>
      <div className='loading-box'></div>
      <div className='loading-box'></div>
    </div>
  );
}
