import React from 'react';
import { Link, useParams } from 'react-router-dom';
import HeaderContents from '../../contents/HeaderContents.json';
import { createUseStyles } from 'react-jss';
import { Navbar, ListGroup } from 'react-bootstrap';
import logo from '../../assets/vectors/header-logo.svg';
import phoneVector from '../../assets/vectors/phone-call.svg';
import langVector from '../../assets/vectors/internet.svg';
import { useTheme } from 'theming';
import clsx from 'clsx';

const useStyles = createUseStyles({
  root: {
    position: 'relative',
    padding: 0,
    direction: (props) => (props.ltr ? 'ltr' : 'rtl'),

    '& a': {
      position: 'relative',
      fontSize: 16,
      lineHeight: 1.56,
      display: 'inline-block',
      fontWeight: 600,
      padding: '0.75rem 0',
    },
  },
  brand: {
    position: 'relative',
    marginRight: (props) => (props.ltr ? 51 : 0),
    marginLeft: (props) => (!props.ltr ? 51 : 0),

    '&:after': {
      content: '""',
      position: 'absolute',
      top: '.3125rem',
      left: (props) => (props.ltr ? 'auto' : -25),
      right: (props) => (!props.ltr ? 'auto' : -25),
      width: 1,
      height: 29,
      backgroundColor: (props) => props.theme.secondary,
    },
  },
  menuItem: {
    border: 'none',
    padding: 0,
    marginLeft: (props) => (props.ltr ? 0 : 40),
    marginRight: (props) => (!props.ltr ? 0 : 40),
  },
  contact: {
    marginRight: (props) => (props.ltr ? 40 : 0),
    marginLeft: (props) => (!props.ltr ? 40 : 0),
    direction: 'ltr',
  },
});

export default function Nav() {
  const theme = useTheme();
  const { lang } = useParams();
  const classes = useStyles({ theme: theme, ltr: lang === 'en' });

  return (
    <Navbar bg='white' className={clsx(classes.root, lang, 'd-none d-lg-flex')}>
      <Navbar.Brand className={classes.brand}>
        <img src={logo} alt='synapps logo' width={143} />
      </Navbar.Brand>

      <div className='w-100 d-flex justify-content-between'>
        <ListGroup horizontal>
          {HeaderContents.menu.map((item, index) => (
            <ListGroup.Item key={index} className={classes.menuItem}>
              <a href={item.href}>{item.label[lang || 'fa']}</a>
            </ListGroup.Item>
          ))}
        </ListGroup>

        <div>
          <a
            href={
              lang
                ? `/${lang}${HeaderContents.contactUs.href}`
                : HeaderContents.contactUs.href
            }
            className={classes.contact}
          >
            <img
              src={phoneVector}
              alt='contact vector'
              width={19}
              className='mr-2'
            />
            {HeaderContents.contactUs.label[lang || 'fa']}
          </a>
          <Link to={lang ? '/' : '/en'}>
            <img
              src={langVector}
              alt='language vector'
              width={24}
              className='mr-2'
            />
          </Link>
        </div>
      </div>
    </Navbar>
  );
}
