import React from 'react';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useTheme } from 'react-jss';
import { useStyles } from './styles';

export default function ProjectCard({
  activeIcon,
  inactiveIcon,
  title,
  text,
  ...rest
}) {
  const { lang } = useParams();
  const theme = useTheme();
  const classes = useStyles({
    theme: theme,
    ltr: lang === 'en',
    activeIcon: activeIcon,
    inactiveIcon: inactiveIcon,
  });

  return (
    <Card className={classes.card} {...rest}>
      <Card.Header className={classes.cardHeader}>
        <div className={classes.cardLogo}>
          <img className={'active-icon'} src={activeIcon} alt='project logo' />
          <img src={inactiveIcon} alt='project logo' />
        </div>

        {/* {mobileMedia ? (
          
        ) : (
          <Textfit
            min={20}
            max={28}
            mode='single'
            className={classes.cardHeaderTitle}
          >
            {title[lang || 'fa']}
          </Textfit>
        )} */}
        <h3 className={classes.cardHeaderTitle}>{title[lang || 'fa']}</h3>
      </Card.Header>
      <Card.Body>
        <p className={classes.cardInfo}>{text[lang || 'fa']}</p>
      </Card.Body>
    </Card>
  );
}
