import { createUseStyles } from 'react-jss';
import { breakpoints } from '../../theme';

export const useStyles = createUseStyles(() => ({
  root: {
    height: '100vh',
    minHeight: 720,
    paddingTop: 48,
    paddingBottom: 126,
  },
  title: {
    textAlign: 'center',
    marginBottom: 80,
  },

  '@media (orientation: portrait)': {
    root: {
      height: 'auto',
    },
  },

  [breakpoints.lg.down]: {
    root: {
      height: 'auto',
      minHeight: 800,
      paddingBottom: 22,
    },
    title: {
      marginBottom: 32,
    },
  },
}));

export const useProductPreviewStyles = createUseStyles(() => ({
  root: {
    position: 'relative',
    height: 500,
    borderRadius: 10,
    transition: 'background-image 0.3s',
  },
  imageWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&.bottom': {
      alignItems: 'flex-end',
    },
  },
  img: {
    borderRadius: 6,
    width: 'calc(100% - 72px)',
    maxHeight: 'calc(100% - 36px)',
    objectFit: 'fill',
  },

  [breakpoints.lg.down]: {
    root: {
      height: 300,
    },
    img: {
      height: 'calc(100% - 21px)',
    },
  },

  [breakpoints.md.down]: {
    root: {
      height: 240,
    },
    img: {
      maxWidth: 315,
    },
  },
}));

export const useProductsListStyles = createUseStyles(() => ({
  root: {
    height: '100%',
    borderRight: (props) => (props.ltr ? 'none' : '2px solid #e4ebf2'),
    borderLeft: (props) => (props.ltr ? '2px solid #e4ebf2' : 'none'),
  },
  title: {
    marginBottom: 32,
    fontWeight: (props) => (props.ltr ? 600 : 700),
    fontSize: (props) => (props.ltr ? 28 : 32),
    textAlign: (props) => (props.ltr ? 'left' : 'right'),
    color: '#b9bec7',
    lineHeight: 1.55,
    cursor: 'pointer',
    transition: '0.3s',

    '&:hover': {
      color: '#27282c',
    },

    '&.active': {
      color: '#27282c',
      marginBottom: 14,
    },
  },
  content: {
    position: 'relative',
    transition: 'all 300ms linear',

    '&.active': {
      paddingBottom: 10,

      '&:before': {
        content: '""',
        width: 6,
        top: 0,
        right: (props) => (props.ltr ? 'auto' : -23),
        left: (props) => (props.ltr ? -23 : 'auto'),
        height: '100%',
        backgroundColor: 'var(--contentBg)',
        position: 'absolute',
        borderRadius: 6,
        zIndex: 1,
      },
    },
  },
  info: {
    lineHeight: 1.56,
    direction: (props) => (props.ltr ? 'ltr' : 'rtl'),
    textAlign: 'justify',
    maxWidth: 440,
  },
  item: {
    position: 'relative',
    paddingRight: (props) => (props.ltr ? 0 : 18.5),
    paddingLeft: (props) => (props.ltr ? 18.5 : 0),

    '&:not(:nth-last-child(1))': {
      '&.active': {
        paddingBottom: 28,
      },
    },
  },

  [breakpoints.lg.down]: {
    root: {
      borderRightWidth: () => 1,
      borderLeftWidth: () => 1,
    },
    title: {
      fontSize: () => 20,
      lineHeight: 1.55,
      marginBottom: 31,

      '&.active': {
        marginBottom: 12,
      },
    },
    content: {
      '&.active:before': {
        width: 3,
        right: (props) => (props.ltr ? 'auto' : -21),
        left: (props) => (props.ltr ? -21 : 'auto'),
      },
    },
    info: {
      fontSize: 14,
      lineHeight: 1.56,
      paddingLeft: 5,
      marginBottom: 20,
      maxWidth: '100%',
    },
    item: {
      paddingRight: 6,
    },
    indicator: {
      right: 15,
      width: 2,
    },
  },
}));
