import { createUseStyles } from 'react-jss';
import { breakpoints } from '../../theme';
import HomeBg from '../../assets/images/home-bg.png';
import HomeBgFlip from '../../assets/images/home-bg-flip.png';

export const useStyles = createUseStyles({
  root: {
    textAlign: 'right',
    minHeight: 700,
    paddingTop: 150,
  },
  container: {
    position: 'relative',
    minHeight: 440,
    paddingTop: 47,
    overflow: 'hidden',
    backgroundImage: (props) => `url('${props.ltr ? HomeBgFlip : HomeBg}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'calc(100% - 260px)',
    backgroundPositionY: -20,
    backgroundPositionX: (props) => (props.ltr ? 260 : 0),

    '& .row': {
      position: 'relative',
      zIndex: 1,
    },
  },
  networkLottie: {
    marginTop: -30,
  },
  subTitle: {
    margin: 0,
  },
  title: {
    margin: 0,
  },
  info: {
    fontFamily: 'Yekan-Bakh-FaNum',
    fontSize: 16,
    lineHeight: 1.56,
    direction: 'rtl',
    margin: (props) => (props.ltr ? '20px auto 48px 0' : '20px 0 48px auto'),
    maxWidth: 521,
  },
  animation: {
    position: 'relative',
    height: '100%',
    marginTop: -50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [breakpoints.lg.down]: {
    root: {
      paddingTop: 80,
    },
    container: {
      paddingTop: 34,
      paddingBottom: 22,
      backgroundImage: () => 'none',
      backgroundSize: 'calc(100% - 48px)',
      backgroundPositionY: -20,
      backgroundPositionX: () => 24,

      '& .dot-background': {
        position: 'absolute',
        top: -30,
        left: 15,
        width: 'calc(100% - 30px)',
        height: 'calc(100% + 75px)',
        zIndex: -1,
      },
    },
    subTitle: {
      fontSize: 16,
    },
    title: {
      fontSize: 40,
    },
    info: {
      fontSize: 14,
      lineHeight: 1.57,
      maxWidth: '100%',
      margin: (props) => (props.ltr ? '12px auto 20px 0' : '12px 0 48p20auto'),
    },
    animation: {
      height: 400,
      width: 400,
      margin: '-50px auto -50px',
    },
  },

  [breakpoints.md.down]: {
    subTitle: {
      marginTop: 25,
    },
    animation: {
      height: 400,
      width: 400,
      margin: '-50px auto -50px',
    },
  },

  [breakpoints.sm.down]: {
    animation: {
      height: 'auto',
      width: 'calc(100% + 80px)',
      marginRight: (props) => (props.ltr ? -40 : 'auto'),
      marginLeft: (props) => (props.ltr ? 'auto' : -40),
    },
  },
});
