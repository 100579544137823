export const theme = {
  dark: '#27282c',
  primary: '#1b90fa',
  secondary: '#b9bec7',
  success: '#47d53c',
  gray: '#8e9299',
  breakpointXs: 0,
  breakpointSm: 576,
  breakpointMd: 768,
  breakpointLg: 992,
  breakpointXl: 1280,
};

export const breakpoints = {
  sm: {
    up: `@media (min-width: ${theme.breakpointSm}px)`,
    down: `@media (max-width: ${theme.breakpointSm}px)`,
  },
  md: {
    up: `@media (min-width: ${theme.breakpointMd}px)`,
    down: `@media (max-width: ${theme.breakpointMd}px)`,
  },
  lg: {
    up: `@media (min-width: ${theme.breakpointLg}px)`,
    down: `@media (max-width: ${theme.breakpointLg}px)`,
  },
  xl: {
    up: `@media (min-width: ${theme.breakpointXl}px)`,
    down: `@media (max-width: ${theme.breakpointXl}px)`,
  },
};
