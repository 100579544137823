import clsx from 'clsx';
import React from 'react';
import { createUseStyles } from 'react-jss';
import typeFace from '../../assets/vectors/synapps-typeface.svg';
import JumpAndSlide from './JumpAndSlide';

const useStyles = createUseStyles({
  root: {
    direction: 'ltr',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 1052,
    transition: 'all 1s',
  },
  typeFace: {
    marginBottom: 60,
    width: 250,
  },
  content: {
    transform: 'translateX(6px)',
  },
});

export default function Loading({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <img src={typeFace} alt='synapps typeface' className={classes.typeFace} />
      <div className={classes.content}>
        <JumpAndSlide />
      </div>
    </div>
  );
}
