import React, { useContext } from 'react';
import {
  Accordion,
  AccordionContext,
  useAccordionToggle,
} from 'react-bootstrap';
import clsx from 'clsx';
import ProductContents from '../../contents/ProductContents';
import { useProductsListStyles } from './styles';
import ProductPreview from './ProductPreview';
import { useParams } from 'react-router-dom';

function Toggle({ className, children, eventKey, onClick }) {
  const active = useContext(AccordionContext) === eventKey;
  const decoratedOnClick = useAccordionToggle(eventKey, onClick);

  return (
    <h3
      className={clsx(className, active && 'active')}
      onClick={active ? () => {} : decoratedOnClick}
    >
      {children}
    </h3>
  );
}

function Indicator({ ...rest }) {
  return <span {...rest} />;
}

const ProductsList = ({ activeKey, setActiveKey }) => {
  const { lang } = useParams();
  const classes = useProductsListStyles({ ltr: lang === 'en' });

  const handleItemClick = (e, eventKey) => {
    setActiveKey(eventKey);
  };

  return (
    <Accordion className={classes.root} defaultActiveKey={activeKey}>
      {ProductContents.products.map((item) => {
        return (
          <div
            key={item.key}
            className={clsx(classes.item, activeKey === item.key && 'active')}
          >
            <div
              className={clsx(
                classes.content,
                activeKey === item.key && 'active'
              )}
              style={{ '--contentBg': item.indicatorColor }}
            >
              <Toggle
                className={classes.title}
                eventKey={item.key}
                onClick={(e) => handleItemClick(e, item.key)}
              >
                {item.title[lang || 'fa']}
              </Toggle>
              <Accordion.Collapse className={classes.info} eventKey={item.key}>
                <p>{item.description[lang || 'fa']}</p>
              </Accordion.Collapse>
            </div>
            {item.key === activeKey && (
              <ProductPreview activeKey={activeKey} className='d-lg-none' />
            )}
          </div>
        );
      })}
      <Indicator className={classes.indicator} />
    </Accordion>
  );
};

export default ProductsList;
