import 'core-js/es/map';
import 'core-js/es/set';
import React from 'react';
import ReactDOM from 'react-dom';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './res.css';
import synapps from './assets/synapps-ascii-art.txt';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LoadingContextProvider } from './contexts/LoadingContext';
import GA4React from 'ga-4-react';

fetch(synapps)
  .then((r) => r.text())
  .then((text) => {
    console.log(text, 'color: rgb(0, 239, 55)');
  });

const trackingId = 'G-8KP97N4EK7';
try {
  setTimeout(() => {
    const ga4react = new GA4React(trackingId);
    ga4react.initialize();
  }, 4000);
} catch (err) {
  console.error(err);
}

ReactDOM.render(
  <React.StrictMode>
    <LoadingContextProvider>
      <App />
    </LoadingContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
