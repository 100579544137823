import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useTheme } from 'react-jss';
import clsx from 'clsx';
import ContactContents from '../../contents/ContactContents.json';
import { useStyles } from './styles';

import twitterLogo from '../../assets/vectors/twitter-logo.svg';
import telegramLogo from '../../assets/vectors/telegram-logo.svg';
import linkedInLogo from '../../assets/vectors/linked-in-logo.svg';

import phoneIcon from '../../assets/vectors/phone-call.svg';
import mailIcon from '../../assets/vectors/mail.svg';
import locationIcon from '../../assets/vectors/location.svg';

import synappsLogo from '../../assets/vectors/header-logo.svg';

const Footer = () => {
  const theme = useTheme();
  const { lang } = useParams();
  const classes = useStyles({ theme: theme, ltr: lang === 'en' });

  const SocialLink = ({ href, src }) => (
    <a
      href={href}
      target='_blank'
      rel='noopener noreferrer'
      key={src}
      aria-label={src}
    >
      <img src={src} alt='link icon' />
    </a>
  );

  const ContactInfoCol = ({
    href,
    src,
    text,
    en = false,
    component = 'div',
  }) => (
    <Col className={classes.contactInfo}>
      <img src={src} className={classes.contactInfoIcon} alt='item icon' />
      {href ? (
        <a
          href={href}
          className={clsx(
            classes.contactInfoText,
            en && lang !== 'en' && 'en-font'
          )}
        >
          {text}
        </a>
      ) : (
        React.createElement(
          component,
          { className: classes.contactInfoText },
          text
        )
      )}
    </Col>
  );

  return (
    <footer className={classes.root}>
      <Container fluid>
        <div className={classes.topLogoContainer}>
          <img src={synappsLogo} className={classes.topLogo} alt='Logo Name' />
        </div>
        <Row className={classes.container}>
          <Col xs={6} lg={7} className={'d-flex d-lg-block align-items-center'}>
            <div
              className={clsx(
                classes.social,
                lang === 'en' ? 'text-right' : 'text-right text-lg-left'
              )}
            >
              <SocialLink
                href='https://www.linkedin.com/company/synappsgroup'
                src={linkedInLogo}
              />
              <SocialLink href='https://t.me/synappsgroup' src={telegramLogo} />
              <SocialLink
                href='https://twitter.com/synapps_group'
                src={twitterLogo}
              />
            </div>
          </Col>
          <Col className={classes.mobileLogoContainer} xs={6} lg={5}>
            <img
              src={synappsLogo}
              className={classes.mobileLogo}
              alt='synapps logo'
            />
          </Col>
          <Col xs={12} lg={5} className='text-right'>
            <ContactInfoCol
              src={phoneIcon}
              text={ContactContents.phone}
              href={`tel:${ContactContents.phone}`}
            />
            <ContactInfoCol
              en
              src={mailIcon}
              text={ContactContents.mail}
              href={`mailto:${ContactContents.mail}`}
            />
            <ContactInfoCol
              component={'p'}
              src={locationIcon}
              text={ContactContents.address[lang || 'fa']}
            />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
