import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import ProjectContents from '../../contents/ProjectContents.js';
import { useTheme } from 'react-jss';
import { useStyles } from './styles';
import clsx from 'clsx';
import Fade from 'react-reveal/Fade';
import ProjectCard from './ProjectCard';
import SliderArrow from './SliderArrow.jsx';

// let refreshedNum = 0;

export default function Projects() {
  const owlRef = useRef();
  const { lang } = useParams();
  const theme = useTheme();
  const classes = useStyles({ theme: theme, ltr: lang === 'en' });

  // const sliderOptions = {
  //   infinite: true,
  //   centerMode: true,
  //   className: classes.slider,
  //   slidesToShow: 3,
  //   arrows: false,
  //   speed: 650,
  //   ref: sliderRef,
  //   responsive: [
  //     {
  //       breakpoint: theme.breakpointXl,
  //       settings: {
  //         centerMode: true,
  //         slidesToShow: 1,
  //       },
  //     },
  //     {
  //       breakpoint: theme.breakpointSm,
  //       settings: {
  //         centerMode: false,
  //         slidesToShow: 1,
  //       },
  //     },
  //   ],
  // };

  // useEffect(() => {
  //   console.log('useEffect');
  //   if (!owlRef.current) return;
  //   document
  //     .querySelectorAll(`.${classes.owlCarousel.replace(' ', '.')} .owl-item`)
  //     .forEach((item) => {
  //       console.log(item);
  //       item.addEventListener('click', (event) => {
  //         console.log('clicked');
  //       });
  //     });
  // }, [owlRef.current]);

  const gotoNext = () => {
    owlRef.current?.next();
  };

  const gotoPrev = () => {
    owlRef.current?.prev();
  };

  // const handleProjectCardClick = (index) => {
  //   owlRef.current?.to(index, 200);
  // };

  const owlOptions = {
    key: 5,
    items: 1,
    className: classes.owlCarousel,
    ref: owlRef,
    loop: true,
    nav: false,
    dots: false,
    center: false,
    margin: 24,
    navSpeed: 1000,
    responsive: {
      [theme.breakpointSm]: {
        center: true,
        stagePadding: 80,
      },
      [theme.breakpointXl]: {
        center: true,
        stagePadding: 80,
        items: 3,
      },
    },
  };

  return (
    <section
      id='projects'
      className={clsx(classes.root, 'gradient-bg-default dot-background op-20')}
    >
      <SliderArrow anchor='right' onArrowClick={gotoNext} />
      <Container className={classes.container}>
        <Row>
          <Col xs={12}>
            <Fade>
              <h2 className={clsx(classes.title, 'text-center')}>
                {ProjectContents.title[lang || 'fa']}
              </h2>
            </Fade>
          </Col>
          <Col xs={12}>
            <Fade bottom>
              <OwlCarousel
                {...owlOptions}
                // onRefreshed={() => {
                //   console.log('refreshed');
                //   refreshedNum++;
                //   if (refreshedNum !== 4) return;

                //   console.log(owlRef.current);
                //   document
                //     .querySelectorAll(
                //       `.${classes.owlCarousel.replace(' ', '.')} .owl-item`
                //     )
                //     .forEach((item, index) => {
                //       item.addEventListener('click', () => {
                //         console.log(index);
                //         owlRef.current?.to(index);
                //       });
                //     });
                // }}
                // onClick={(e) => {
                //   console.log(e);
                // }}
                // onChange={(e) => {
                //   console.log(e);
                // }}
                // onDragged={handleCarouselChanged}
              >
                {ProjectContents.cards.map((card, index) => (
                  <ProjectCard
                    key={index}
                    // onClick={() => handleProjectCardClick(index)}
                    {...card}
                  />
                ))}
              </OwlCarousel>
            </Fade>
          </Col>
        </Row>
      </Container>
      <SliderArrow anchor='left' onArrowClick={gotoPrev} />
    </section>
  );
}
