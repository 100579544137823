import React, { useContext, useEffect } from 'react';
import FontFaceObserver from 'fontfaceobserver';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'react-jss';
import { theme } from './theme';
import './App.css';
import Loading from './components/Loading';
import MainLayout from './containers/MainLayout/MainLayout';
import loadingContext from './contexts/LoadingContext';

function App() {
  const { loaded, setLoaded } = useContext(loadingContext);

  useEffect(() => {
    if (loaded) return;

    const YekanFont = new FontFaceObserver('Yekan-Bakh-Fa-En');
    const YekanFaFont = new FontFaceObserver('Yekan-Bakh-FaNum');
    const AxiformaFont = new FontFaceObserver('Axiforma');

    Promise.all([YekanFont.load(), YekanFaFont.load(), AxiformaFont.load()])
      .then(() => {
        setLoaded('fonts');
      })
      .catch((err) => {
        setLoaded('fonts');
        console.log(err);
      });
  }, []);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Switch>
          <Route path='/:lang?'>
            <MainLayout className={loaded ? '' : 'invisible'} />
            {!loaded && <Loading />}
          </Route>
        </Switch>
      </ThemeProvider>
    </Router>
  );
}

export default App;
