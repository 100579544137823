import React, { useState, useEffect, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useStyles } from './styles';
import ProductContents from '../../contents/ProductContents';
import ProductsList from './ProductsList';
import clsx from 'clsx';
import ProductPreview from './ProductPreview';
import { useParams } from 'react-router-dom';
import loadingContext from '../../contexts/LoadingContext';
import loadImage from '../../utilities/loadImage';

const Products = () => {
  const { lang } = useParams();
  const rtl = !lang || lang === 'fa';
  const classes = useStyles();
  const [activeKey, setActiveKey] = useState('inlab');
  const { setLoaded } = useContext(loadingContext);

  useEffect(() => {
    Promise.all(
      ProductContents.products.map((product) =>
        loadImage(product.icon[lang || 'fa'])
      )
    )
      .then(() => {
        setLoaded('productImages');
      })
      .catch((err) => {
        setLoaded('productImages');
        console.log('Failed to load images', err);
      });
  }, []);

  return (
    <section className={classes.root} id='products'>
      <Container fluid>
        <h2 className={clsx(classes.title, lang === 'en' && 'en')}>
          {ProductContents.title[lang || 'fa']}
        </h2>
        <Row dir={rtl ? 'rtl' : 'ltr'}>
          <Col lg={6} className='products-list'>
            <ProductsList activeKey={activeKey} setActiveKey={setActiveKey} />
          </Col>
          <Col
            lg={6}
            className={clsx('d-none d-lg-block', classes.previewContainer)}
          >
            <ProductPreview activeKey={activeKey} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Products;
