import React, { createContext, useState } from 'react';

const loadingContext = createContext({
  loaded: false,
  setLoaded: () => {},
});

export default loadingContext;

export function LoadingContextProvider(props) {
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [productImagesLoaded, setProductImagesLoaded] = useState(false);

  const toggleState = (key) => {
    if (key === 'fonts') {
      setFontsLoaded(true);
    } else if (key === 'productImages') {
      setProductImagesLoaded(true);
    }
  };

  return (
    <loadingContext.Provider
      value={{
        loaded: fontsLoaded && productImagesLoaded,
        setLoaded: toggleState,
      }}
    >
      {props.children}
    </loadingContext.Provider>
  );
}
