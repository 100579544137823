import activeCMC from '../assets/vectors/projects/cmc-active.svg';
import inactiveCMC from '../assets/vectors/projects/cmc-inactive.svg';
import activeMinistry from '../assets/vectors/projects/ministry-active.svg';
import inactiveMinistry from '../assets/vectors/projects/ministry-inactive.svg';
import activeNeoronio from '../assets/vectors/projects/neoronio-active.svg';
import inactiveNeoronio from '../assets/vectors/projects/neoronio-inactive.svg';
import activeSina from '../assets/images/projects/sina-active.png';
import inactiveSina from '../assets/images/projects/sina-inactive.png';
import activeDruket from '../assets/vectors/projects/druket-active.svg';
import inactiveDruket from '../assets/vectors/projects/druket-inactive.svg';

const ProjectContents = {
  title: { fa: 'پروژه های سیناپس', en: 'Our products' },
  cards: [
    {
      activeIcon: activeMinistry,
      inactiveIcon: inactiveMinistry,
      title: {
        fa: 'وزارت بهداشت، درمان و آموزش پزشکی',
        en: 'Ministry of Health and Medical Education',
      },
      text: {
        fa:
          'سیناپس با طراحی نرم افزارهای پیش الگو و ارائه کدینگ ها و استاندارد های لازم، با وزارت بهداشت کشور ایران همکاری کرده  تا این نهاد در راستای تحقق پرونده الکترونیک سلامت فرآیند الکترونیکی کردن فرم های بیمارستانی در کشور را با سرعت و کیفیت بالاتری پیش ببرد.',
        en:
          'Our cooperation aims to accelerate the process of electronizing hospital forms with higher quality. For this purpose we design form prototypes and prepare medical term codings and required standards.',
      },
    },
    {
      activeIcon: activeCMC,
      inactiveIcon: inactiveCMC,
      title: {
        fa: 'بیمارستان مرکز طبی کودکان دانشگاه علوم پزشکی تهران',
        // eslint-disable-next-line quotes
        en: "Children's Medical Center of Tehran University of Medical Science",
      },
      text: {
        fa:
          'پروسه ی هوشمند سازی این بیمارستان با نرم افزار اینلب در نسخه های موبایل و دسکتاپ از سه سال پیش آغاز شده است و توانستیم بخش بزرگی از اطلاعات سلامت بیماران را بدون ایجاد فرسودگی برای کادر درمان ثبت و آنلاین نماییم.',
        en:
          'The process of smartening this hospital started with mobile and desktop versions of InLab from 3 years ago. We could record large data and make it available online without introducing burnout to staff.',
      },
    },
    {
      activeIcon: activeSina,
      inactiveIcon: inactiveSina,
      title: {
        fa: 'بیمارستان سینا دانشگاه علوم پزشکی تهران',
        en: 'Sina Hospital of Tehran University of Medical Science',
      },
      text: {
        fa:
          'پروسه ی هوشمند سازی این بیمارستان با نرم افزار اینلب در نسخه های موبایل و دسکتاپ از دو سال پیش آغاز شده است و توانستیم بخش بزرگی از اطلاعات سلامت بیماران را بدون ایجاد فرسودگی برای کادر درمان ثبت و آنلاین نماییم.',
        en:
          'The process of smartening this hospital started with mobile and desktop versions of InLab from 2 years ago. We could record large data and make it available online without introducing burnout to staff.',
      },
    },
    {
      activeIcon: activeNeoronio,
      inactiveIcon: inactiveNeoronio,
      title: { fa: 'پلی کلینیک هوشمند نورونیو', en: 'Neuronio Smart Polyclinic' },
      text: {
        fa:
          'درمانگاه تماما هوشمند نورونیو تمامی اطلاعات بیماران را از طریق نرم افزار مدیریت کلینیک سیناپس در خود داشته و به کمک سیستم رجیستری اطلاعات سیناپس از تحلیل این اطلاعات برای بهبود روند درمان بیماران استفاده میکند.',
        en:
          'With the purpose of improving the treatment process, Neuronio polyclinic records all patients’ data in our clinic management application and analyses this data with our registry system.',
      },
    },
    {
      activeIcon: activeDruket,
      inactiveIcon: inactiveDruket,
      title: { fa: 'دراکت', en: 'Druket' },
      text: {
        fa:
          'مدیریت انبارها و مجموعه داروخانه های دراکت به کمک نرم افزار مدیریت داروخانه ی سیناپس به صورت کاملا هوشمند انجام می گیرد.',
        en:
          'Stocks and stores of Druket pharmacy chain are managed using our pharmacy management application.',
      },
    },
  ],
};

export default ProjectContents;
