import React, { Fragment, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import clsx from 'clsx';
import { breakpoints } from '../../theme';
import { createUseStyles } from 'react-jss';
import { useTheme } from 'theming';
import Nav from './Nav';
import MobileNav from './MobileNav';

const useStyles = createUseStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    paddingTop: 58,
    backgroundColor: 'white',
    zIndex: 1051,

    [breakpoints.lg.down]: {
      paddingTop: 24,
      paddingBottom: 0,

      '&.fixed': {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
      },
    },
  },
});

const Header = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [expandMenu, setExpandMenu] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);

  useEffect(() => {
    if (expandMenu) {
      setShowBackdrop(true);
    } else if (showBackdrop.current) {
      setTimeout(() => {
        setShowBackdrop(false);
      }, 300);
    } else {
      setShowBackdrop(false);
    }
  }, [expandMenu]);

  return (
    <Fragment>
      <header className={clsx(classes.root, expandMenu && 'fixed')}>
        <Container fluid>
          <Nav />
          <MobileNav expanded={expandMenu} setExpanded={setExpandMenu} />
        </Container>
      </header>
      {showBackdrop && (
        <div
          className={clsx('backdrop fade d-lg-none', expandMenu && 'show')}
          onClick={() => setExpandMenu(false)}
        />
      )}
    </Fragment>
  );
};

export default Header;
