import { createUseStyles } from 'react-jss';
import { breakpoints } from '../../theme';

export const useStyles = createUseStyles({
  root: {
    position: 'relative',
    height: '100vh',
    minHeight: 645,
    padding: {
      top: 40,
      bottom: 0,
    },
  },
  container: {
    padding: 0,
    maxWidth: '100vw',
    overflow: 'hidden',
  },
  title: {
    paddingBottom: 80,
    margin: 0,
  },
  card: {
    border: 'none',
    borderRadius: 10,
    boxShadow: '0 16px 40px 0 rgba(77, 86, 92, 0.2)',
    backgroundColor: 'white',
    transition: 'all 0.2s',
    direction: (props) => (props.ltr ? 'ltr' : 'rtl'),
    cursor: 'pointer',

    '& .card-body': {
      transition: 'all 0.2s',
    },
  },
  cardHeader: {
    border: 'none',
    backgroundColor: 'transparent',
    borderRadius: 10,
    color: (props) => props.theme.secondary,
    padding: '32px 32px 0 32px',
    zIndex: 2,
    transition: 'all 0.2s',
  },
  cardLogo: {
    position: 'relative',
    marginBottom: 12,
    height: 53,
    width: '100%',

    '& img': {
      position: 'absolute',
      top: 0,
      left: (props) => (props.ltr ? 0 : 'auto'),
      right: (props) => (props.ltr ? 'auto' : 0),
      width: 'auto !important',
      height: '100%',
      transition: 'all 0.2s',

      '&.active-icon': {
        visibility: 'hidden',
      },
    },
  },
  cardHeaderTitle: {
    fontSize: 28,
    fontWeight: 600,
    lineHeight: 1.54,
    textAlign: (props) => (props.ltr ? 'left' : 'right'),
    margin: 0,
    transition: 'all 0.2s',
  },
  cardInfo: {
    color: (props) => props.theme.secondary,
    fontFamily: (props) => (props.ltr ? 'Axiforma' : 'Yekan-Bakh-FaNum'),
    fontSize: 14,
    position: 'relative',
    textAlign: (props) => (props.ltr ? 'left' : 'right'),
    zIndex: 2,
    transition: 'all 0.2s',
  },
  owlCarousel: {
    direction: 'ltr',

    '& .card-body': {
      position: 'relative',
      visibility: 'visible',
      padding: '20px 32px 32px',
    },

    '& .owl-stage-outer': {
      '& .owl-item': {
        direction: 'rtl',
        paddingBottom: 130,

        '&.center': {
          '& $card': {
            boxShadow: '0 40px 72px 0 rgba(78, 85, 92, 0.24)',
          },
          '& $cardHeader': {
            color: (props) => props.theme.primary,
          },
          '& $cardLogo': {
            '& img': {
              visibility: 'hidden',

              '&.active-icon': {
                visibility: 'visible',
              },
            },
          },
          '& $cardHeaderTitle': {
            // fontSize: 32,
          },
          '& $cardInfo': {
            color: (props) => props.theme.dark,
          },
        },
      },
    },
  },
  slider: {
    padding: 0,

    '& .slick-list, .slick-track': {
      maxHeight: 570,
      paddingBottom: 170,
    },

    '& .slick-track': {
      display: 'flex',
    },

    '& .slick-slide': {
      float: 'left',
      padding: '0 16px',
    },
    '& .card-body': {
      position: 'relative',
      visibility: 'visible',
      padding: '20px 32px 32px',
    },
    '& .slick-center': {
      '& $card': {
        boxShadow: '0 40px 72px 0 rgba(78, 85, 92, 0.24)',
      },
      '& $cardHeader': {
        color: (props) => props.theme.primary,
      },
      '& $cardHeaderTitle': {
        fontSize: 32,
      },
      '& $cardInfo': {
        color: (props) => props.theme.dark,
      },
    },
  },

  '@media (orientation: portrait)': {
    root: {
      height: 'auto',
      minHeight: 0,
    },
  },

  [breakpoints.lg.down]: {
    root: {
      height: '',
      minHeight: 600,
      background: 'linear-gradient(128deg, #1B90FA, #84C0F5 100%)',
      // paddingBottom: 40,
    },
    title: {
      paddingBottom: 40,
    },
  },

  [breakpoints.sm.down]: {
    owlCarousel: {
      '& .owl-stage-outer': {
        '& .owl-item': {
          padding: '0 36px 130px',

          '&.active': {
            '& $card': {
              boxShadow: '0 40px 72px 0 rgba(78, 85, 92, 0.24)',
              '&:before, &:after': {
                opacity: 0,
              },
            },
            '& $cardLogo': {
              '& img': {
                visibility: 'hidden',

                '&.active-icon': {
                  visibility: 'visible',
                },
              },
            },
            '& $cardHeader': {
              color: (props) => props.theme.primary,
              paddingBottom: 0,
            },
            '& $cardInfo': {
              color: (props) => props.theme.dark,
            },
          },
        },
      },
    },
  },
});

export const useSliderArrowStyles = createUseStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: 150,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    zIndex: 1,

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      opacity: 0.6,
      background:
        'linear-gradient(89deg, rgba(73,164,247,1) 3%, rgba(70,162,246,0) 97%)',
    },

    '& $arrowButton:hover': {
      transform: 'translateX(-10px)',
    },

    '&.right': {
      right: 0,
      left: 'auto',
      justifyContent: 'flex-end',

      '&:before': {
        background:
          'linear-gradient(269deg, rgba(73,164,247,1) 3%, rgba(70,162,246,0) 97%)',
      },

      '& $arrowButton:hover': {
        transform: 'translateX(10px)',
      },
    },
  },
  arrowButton: {
    width: 52,
    height: 52,
    border: 'none',
    borderRadius: '50%',
    backgroundColor: '#1b90fa',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s',
    zIndex: 2,

    '& img': {
      height: 20,
    },
  },

  [breakpoints.xl.down]: {
    root: {
      width: 85,
    },
  },

  [breakpoints.sm.down]: {
    root: {
      width: 36,

      '&:before': {
        display: 'none',
      },

      '& $arrowButton': {
        width: 28,
        height: 28,
        marginRight: -14,
      },

      '&.right': {
        '& $arrowButton': {
          marginLeft: -14,
        },
      },
    },

    arrowButton: {
      border: '1px solid #1b90fa',
      backgroundColor: 'white',

      '& img': {
        height: 11,
      },
    },
  },
});
