import { createUseStyles } from 'react-jss';
import { breakpoints } from '../../theme';

export const useStyles = createUseStyles({
  root: {
    padding: '120px 0',
  },
  container: {
    borderRadius: 10,
    margin: 0,
    padding: '53px 85px 58px',
    boxShadow: '0 25px 45px 0 rgba(27, 144, 250, 0.4)',
  },
  card: {
    borderRadius: 10,
    border: 'none',

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage:
        'linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255,1))',
      borderRadius: 10,
      zIndex: -1,
    },

    '&:after': {
      borderRadius: 10,
      zIndex: -2,
    },
  },
  cardBody: {
    textAlign: 'left',
    padding: '60px 64px 70px',
  },
  title: {
    marginBottom: 60,
  },
  contactItem: {
    marginBottom: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    flexDirection: 'row-reverse',

    '& img': {
      width: 30,
      marginRight: 16,
    },

    '& a': {
      fontFamily: (props) => (props.ltr ? 'Axiforma' : 'Yekan-Bakh-FaNum'),
      fontSize: (props) => (props.ltr ? 20 : 22),
      lineHeight: 1.84,
      letterSpacing: 1.1,
      fontWeight: (props) => (props.ltr ? 500 : 600),
      zIndex: 1,

      '&.textEn': {
        fontFamily: 'Axiforma',
        fontSize: 19,
        lineHeight: 1.55,
        letterSpacing: 'normal',
        fontWeight: 500,
      },
    },
  },
  animation: {
    position: 'relative',
    margin: '-70px auto',
    height: 'calc(100% + 130px)',
  },
  solidMapWrapper: {
    margin: '0 -5vw',
  },

  [breakpoints.xl.down]: {
    container: {
      padding: '4vw 6vw',
    },
    card: {
      '&:before': {
        backgroundImage:
          'linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,1))',
      },
    },
    cardBody: {
      padding: '4vw 4vw 5vw',
    },
    title: {
      marginBottom: '4.6vw',
    },
    animation: {
      margin: '-4vw auto',
      height: 'calc(100% + 9vw)',
    },
  },

  [breakpoints.lg.down]: {
    root: {
      padding: '60px 0 64px',
    },
    container: {
      borderRadius: 5,
    },
    card: {
      borderRadius: 5,

      '&:after': {
        borderRadius: 5,
      },
    },
  },

  [breakpoints.md.down]: {
    cardBody: {
      padding: '5vw 5vw 0',
    },
    animation: {
      margin: 'calc(-5vw + 20px) auto 0',
      height: 'calc(100% + 5vw)',
    },
  },

  [breakpoints.sm.down]: {
    title: {
      fontSize: 30,
      textAlign: 'center',
      marginBottom: 24,
    },
    contactItem: {
      marginBottom: 16,

      '& img': {
        width: 20,
        marginRight: 12,
      },

      '& a': {
        fontSize: () => 16,
        lineHeight: 1.56,

        '&.textEn': {
          fontSize: 14,
          lineHeight: 1.86,
        },
      },
    },
  },
});
