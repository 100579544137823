import clsx from 'clsx';
import React from 'react';
import { useSliderArrowStyles } from './styles';
import ArrowLeftIcon from '../../assets/vectors/arrow-white-left.svg';
import ArrowRightIcon from '../../assets/vectors/arrow-white-right.svg';
import ArrowLeftPrimaryIcon from '../../assets/vectors/arrow-primary-left.svg';
import ArrowRightPrimaryIcon from '../../assets/vectors/arrow-primary-right.svg';

export default function SliderArrow({ anchor = 'left', onArrowClick }) {
  const classes = useSliderArrowStyles();

  return (
    <div className={clsx(classes.root, anchor)}>
      <button className={classes.arrowButton} onClick={onArrowClick}>
        {anchor === 'left' ? (
          <>
            <img
              src={ArrowLeftIcon}
              alt='arrow left'
              className='d-none d-sm-block'
            />
            <img
              src={ArrowLeftPrimaryIcon}
              alt='arrow left'
              className='d-block d-sm-none'
            />
          </>
        ) : (
          <>
            <img
              src={ArrowRightIcon}
              alt='arrow right'
              className='d-none d-sm-block'
            />
            <img
              src={ArrowRightPrimaryIcon}
              alt='arrow right'
              className='d-block d-sm-none'
            />
          </>
        )}
      </button>
    </div>
  );
}
