import React, { useRef, useState, useEffect, useContext } from 'react';
import Lottie from 'react-lottie';
import { useParams } from 'react-router-dom';
import { Card, Col, Container, Row } from 'react-bootstrap';
import clsx from 'clsx';
import { useTheme } from 'react-jss';
import { useStyles } from './styles';
import loadingContext from '../../contexts/LoadingContext';
import ContactContents from '../../contents/ContactContents.json';

import phoneIcon from '../../assets/vectors/phone-call.svg';
import mailIcon from '../../assets/vectors/mail.svg';
import solidMapVector from '../../assets/vectors/mobile-map.svg';
import solidMapVectorEn from '../../assets/vectors/mobile-map-en.svg';
import animationData from '../../assets/animation/map';
import enAnimationData from '../../assets/animation/map-en';

export default function Contact({ width }) {
  const contactRef = useRef(null);
  const theme = useTheme();
  const { lang } = useParams();
  const classes = useStyles({ theme: theme, ltr: lang === 'en' });
  const [isStoppedAnimation, setIsStoppedAnimation] = useState(true);
  const { loaded } = useContext(loadingContext);
  const mobileMedia = width < 576;

  const observerCallback = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting && isStoppedAnimation) {
      setIsStoppedAnimation(false);
    }
  };
  const observerOptions = {
    root: document.body,
    rootMargin: '0px',
    threshold: 0.6,
  };

  useEffect(() => {
    if (!loaded || mobileMedia) return;
    let observer = null;
    if ('IntersectionObserver' in window) {
      observer = new IntersectionObserver(observerCallback, observerOptions);
      if (contactRef.current) observer.observe(contactRef.current);
    }

    return () => {
      if (contactRef.current && observer)
        observer.unobserve(contactRef.current);
    };
  }, [contactRef, observerOptions]);

  const defaultLottieOptions = {
    loop: false,
    autoplay: false,
    animationData: lang === 'en' ? enAnimationData : animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      filterSize: {
        width: '100px',
        height: '100px',
        x: '-50%',
        y: '-50%',
      },
    },
  };

  const handleOnMapClicked = () => {
    const SynappsLocationHref =
      'https://www.google.com/maps/place/Samsung+AUT+Tech+Center/@35.702459,51.4066193,21z/data=!4m5!3m4!1s0x3f8e010c4e24354b:0xeaa6f056a0f5fef4!8m2!3d35.7025014!4d51.4065349';
    window.open(SynappsLocationHref, '_blank');
  };

  function renderContentItem(icon, href, text, enText) {
    return (
      <div className={classes.contactItem}>
        <img src={icon} alt='icon' />
        <a href={href} className={clsx(enText && lang !== 'en' && 'textEn')}>
          {text}
        </a>
      </div>
    );
  }

  return (
    <section id='contact' className={classes.root} ref={contactRef}>
      <Container fluid>
        <Row className={clsx(classes.container, 'gradient-bg-default')}>
          <Col className='p-0'>
            <Card
              className={clsx(classes.card, !mobileMedia && 'dot-background')}
            >
              <Card.Body className={classes.cardBody}>
                <Row className='flex-column-reverse flex-xl-row'>
                  <Col xl={8}>
                    {mobileMedia ? (
                      <div
                        className={classes.solidMapWrapper}
                        onClick={handleOnMapClicked}
                      >
                        <img
                          src={
                            lang === 'en' ? solidMapVectorEn : solidMapVector
                          }
                          alt='solid map vector'
                          width={'100%'}
                        />
                      </div>
                    ) : (
                      <div
                        className={classes.animation}
                        onClick={handleOnMapClicked}
                      >
                        <Lottie
                          options={defaultLottieOptions}
                          height='100%'
                          width='100%'
                          isClickToPauseDisabled
                          isStopped={isStoppedAnimation}
                        />
                      </div>
                    )}
                  </Col>
                  <Col xl={4}>
                    <h2 className={clsx(classes.title, lang === 'en' && 'en')}>
                      {ContactContents.title[lang || 'fa']}
                    </h2>

                    {renderContentItem(
                      phoneIcon,
                      `tel:${ContactContents.phone}`,
                      ContactContents.phone
                    )}

                    {renderContentItem(
                      mailIcon,
                      `mailto:${ContactContents.mail}`,
                      ContactContents.mail,
                      true
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
