import inlabIcon from '../assets/vectors/products/inlab.svg';
import inlabEnIcon from '../assets/vectors/products/inlab-en.svg';
// import consultIcon from '../assets/vectors/products/consult.svg';
// import consultEnIcon from '../assets/vectors/products/consult-en.svg';
import clinicIcon from '../assets/vectors/products/clinic.svg';
import clinicEnIcon from '../assets/vectors/products/clinic.svg';
import pharmacyIcon from '../assets/vectors/products/pharmacy.svg';
import pharmacyEnIcon from '../assets/vectors/products/pharmacy-en.svg';
import prescriptionIcon from '../assets/vectors/products/prescription.svg';
import prescriptionEnIcon from '../assets/vectors/products/prescription-en.svg';

const content = {
  title: { fa: 'محصــولات سیناپس', en: 'Our Products' },
  products: [
    {
      key: 'inlab',
      title: {
        fa: 'اینلب',
        en: 'InLab',
      },
      description: {
        fa:
          'این نرم افزار در نسخه های دسکتاپ و گوشی به شکلی کاربرد پسند و منطبق با فرایندهای بیمارستانی پیاده سازی شده است که پزشکان بتوانند اطلاعات بیماران بستری خود را از جمله نتایج آزمایش ها،گزارش های رادیولوژی، شرح حال و عکس های ثبت شده از بیمار را در تلفن همراه با سطح دسترسی مشخص و امنیت بالا مشاهده کرده و مشاوره های پزشک با پزشک در طول زمان بستری بیمار را انجام دهند.',
        en:
          'InLab is a multi-platform, user-friendly and hospital-tailored application, enabling doctors to access hospitalized patients data, send consult requests to other doctors and receive their reply. This data includes lab tests, radiology reports, medical history and various imaging of patients which can be observed on a desktop PC or a smartphone. Access to data is restricted based on role definitions to ensure data security.',
      },
      icon: {
        fa: inlabIcon,
        en: inlabEnIcon,
        position: 'bottom',
      },
      indicatorColor: '#00b2d8',
      imageBg: 'linear-gradient(132deg, #007e99, #00b2d8 100%)',
      boxShadow: '0 25px 45px 0 rgba(0, 126, 153, 0.32)',
    },
    {
      key: 'clinic',
      title: { fa: 'مدیریت کلینیک', en: 'Clinic Management' },
      description: {
        fa:
          'سیناپس کلینیک و درمانگاه شما را هوشمند می کند. صف های درمانگاه های پرتراکم را سامان دهید. به راحتی شرح حال، آزمایش ها و گزارش های رادیولوژی بیماران خود را ثبت کنید. از دقت و سادگی ثبت اطلاعات داروهای بیماران شگفت زده شوید و از جدول منظم اطلاعات برای استفاده در مقالات لذت ببرید.',
        en:
          'You will have a smarter clinic when the clinic management application is in charge of organizing your crowded patient queue. Submit medical history, lab tests and radiology reports of the patients with ease and enjoy using structured data in your research.',
      },
      icon: {
        fa: clinicIcon,
        en: clinicEnIcon,
        position: 'center',
      },
      indicatorColor: '#9042b8',
      imageBg: 'linear-gradient(132deg, #156ebd, #9042b8 100%)',
      boxShadow: '0 25px 45px 0 rgba(144, 66, 184, 0.32)',
    },
    {
      key: 'pharmacy',
      title: { fa: 'مدیریت داروخانه', en: 'Pharmacy Management' },
      description: {
        fa:
          'سیناپس نه تنها داروخانه های کوچک بلکه داروخانه های بیمارستانی و زنجیره ای را نیز هوشمند می کند. اگر مدیر داروخانه های زنجیره ای هستید، با سیناپس از دفتر خود به آمار تک تک داروخانه ها دسترسی دارید و با استفاده از بخش گزارش گیری جامع از داروها، موجودی ها، هزینه ها و درآمد های مجموعه داروخانه ها برای خرید عمده در آینده بهتر تصمیم گیری کنید.',
        en:
          'We will make hospital and chain pharmacies smarter as well as small pharmacies. If you are managing chain pharmacies, you can access statistics of any of the stores from your office. Also you can make better planning on your future purchases by using comprehensive reports of drugs, supplies, costs and incomes.',
      },
      icon: {
        fa: pharmacyIcon,
        en: pharmacyEnIcon,
        position: 'center',
      },
      indicatorColor: '#07bb9f',
      imageBg: 'linear-gradient(132deg, #2e2254, #07bb9f 100%)',
      boxShadow: '0 25px 45px 0 rgba(7, 187, 159, 0.32)',
    },
    {
      key: 'prescription',
      title: { fa: 'دستور نویسی الکترونیک', en: 'Electronic Order Entry' },
      description: {
        fa:
          'بر روی گوشی موبایل در ساده ترین، سریع ترین، دقیق ترین و هوشمندانه ترین حالت ممکن برای بیماران بستری دستور گذاری کنید. اعلام تداخلات دارویی، دادن پیشنهادات دارویی دقیق و ارتباط کامل با کاردکس پرستاری تنها چند ویژگی ساده در پیاده سازی این محصول است. اوردرگذاری الکترونیک همه ی این ها هست اما تنها شامل این موارد نیست. بهتر است به جای مطالعه راجع به این نرم افزار آن را دست بگیرید و تست کنید.',
        en:
          'Use the most smart, accurate, fast and simple way of recording medical orders for hospitalized patients just on your smartphone. Drug interaction alerts, helpful drug suggestions, full connection with nursing Cardex. These are only some but not all of the features. Try it for yourself and find out more.',
      },
      icon: {
        fa: prescriptionIcon,
        en: prescriptionEnIcon,
        position: 'bottom',
      },
      indicatorColor: '#004e92',
      imageBg: 'linear-gradient(132deg, #000428, #004e92 100%)',
      boxShadow: '0 25px 45px 0 rgba(0, 78, 146, 0.32)',
    },
  ],
};

export default content;
