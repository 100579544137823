import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import HomeContents from '../../contents/HomeContents.json';
import { useTheme } from 'react-jss';
import { useStyles } from './styles';
import Lottie from 'react-lottie';
import animationData from '../../assets/animation/data';
import enAnimationData from '../../assets/animation/network-en';

export default function Home({ width }) {
  const theme = useTheme();
  const { lang } = useParams();
  const classes = useStyles({ theme: theme, ltr: lang === 'en' });
  // const [selectedNode, setSelectedNode] = useState('synapps');
  // const nodeStrings = HomeContents.networkStrings[selectedNode];
  const nodeStrings = HomeContents.networkStrings['synapps'];
  const mobileMedia = width < 992;

  // useEffect(() => {
  //   Object.keys(HomeContents.networkStrings).forEach((nodeKey) => {
  //     const node = document.querySelector(`#${nodeKey}`);

  //     node.addEventListener('click', (event) => {
  //       event.preventDefault();
  //       setSelectedNode(nodeKey);
  //     });
  //   });
  // }, [lang]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lang === 'en' ? enAnimationData : animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      filterSize: {
        width: '100px',
        height: '100px',
        x: '-50%',
        y: '-50%',
      },
    },
  };

  return (
    <section className={classes.root}>
      <Container fluid className={classes.container}>
        <Row dir={lang === 'en' ? 'rtl' : 'ltr'}>
          <Col xs={12} lg={5} xl={6}>
            {mobileMedia && <div className='dot-background' />}
            <div className={classes.animation}>
              <Lottie
                isStopped={mobileMedia}
                options={defaultOptions}
                isClickToPauseDisabled={true}
                height='auto'
                width='100%'
              />
            </div>
          </Col>
          <Col
            xs={12}
            lg={7}
            xl={6}
            className={lang === 'en' ? 'text-left' : 'text-right'}
          >
            <Fade right={lang !== 'en'} left={lang === 'en'} cascade>
              <div className={lang === 'en' ? 'text-left' : 'text-right'}>
                <h3 className={classes.subTitle}>
                  {nodeStrings.subTitle[lang || 'fa']}
                </h3>
                <h1
                  className={classes.title}
                  style={{ fontSize: lang === 'en' ? '54px' : '64px' }}
                >
                  {nodeStrings.title[lang || 'fa']}
                </h1>
                <p className={classes.info}>{nodeStrings.info[lang || 'fa']}</p>
              </div>
              <a href={'#contact'}>
                <Button>{HomeContents.contact[lang || 'fa']}</Button>
              </a>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
