import { createUseStyles } from 'react-jss';
import { breakpoints } from '../../theme';

export const useStyles = createUseStyles({
  root: {
    paddingBottom: 60,
  },
  container: {
    flexDirection: (props) => (props.ltr ? 'row' : 'row-reverse'),
  },
  contactInfo: {
    paddingRight: (props) => (props.ltr ? 15 : 0),
    paddingLeft: (props) => (props.ltr ? 0 : 15),
    marginRight: 0,
    marginBottom: 20,
    display: 'flex',
    flexDirection: (props) => (props.ltr ? 'row-reverse' : 'row'),
  },
  contactInfoIcon: {
    marginBottom: 'auto',
    marginRight: (props) => (props.ltr ? 20 : 0),
    marginLeft: (props) => (props.ltr ? 0 : 20),
  },
  contactInfoText: {
    fontFamily: (props) => (props.ltr ? 'Axiforma' : 'Yekan-Bakh-FaNum'),
    fontWeight: (props) => (props.ltr ? 300 : 400),
    fontSize: (props) => (props.ltr ? 14 : 16),
    lineHeight: (props) => (props.ltr ? 1.76 : 1.56),
    marginTop: (props) => (props.ltr ? -5 : 0),
    textAlign: (props) => (props.ltr ? 'left' : 'right'),
    whiteSpace: 'break-spaces',

    '&.en-font': {
      fontFamily: () => 'Axiforma',
      fontSize: 14,
      fontWeight: 300,
      marginTop: -5,
    },
  },
  social: {
    '& a': {
      marginRight: (props) => (props.ltr ? 0 : 40),
      marginLeft: (props) => (props.ltr ? 40 : 0),
    },
    '& img': {
      height: 24,
    },
  },
  topLogoContainer: {
    position: 'relative',
    display: 'block',
    textAlign: 'center',
    marginBottom: 46.8,

    '&:before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)',
      width: 'calc(50% - 100px)',
      height: 1,
      backgroundColor: '#b9bec7',
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',
      width: 'calc(50% - 100px)',
      height: 1,
      backgroundColor: '#b9bec7',
    },
  },
  topLogo: {
    height: 30,
  },
  mobileLogoContainer: {
    display: 'none',
  },
  mobileLogo: {
    height: 25,
  },

  [breakpoints.lg.down]: {
    root: {
      paddingBottom: 40,
    },
    container: {
      flexDirection: 'row !important',
    },
    contactInfo: {
      '&:first-child': {
        marginTop: 40,
      },
    },
    social: {
      '& a': {
        marginRight: () => 0,
        marginLeft: () => 40,
      },
    },
    topLogoContainer: {
      marginBottom: 0,
      paddingBottom: 24,

      '&:before': {
        display: 'none',
      },
      '&:after': {
        top: 0,
        transform: 'translateY(0)',
        width: '100%',
      },
    },
    topLogo: {
      display: 'none',
    },
    mobileLogoContainer: {
      display: 'block',
    },
  },

  [breakpoints.md.down]: {
    social: {
      '& a': {
        marginLeft: () => 24,
      },
    },
  },

  [breakpoints.sm.down]: {
    contactInfo: {
      marginBottom: 20,
    },
    contactInfoText: {
      fontSize: 14,
      lineHeight: 1.58,

      '&.en-font': {
        marginTop: -5,
      },
    },
    social: {
      '& a': {
        marginLeft: () => 16,
      },
      '& img': {
        height: 20,
      },
    },
    mobileLogo: {
      width: 100,
    },
  },
});
