import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Products from '../../components/Products';
import Projects from '../../components/Projects';
import Footer from '../../components/Footer';
import Contact from '../../components/Contact';
import Home from '../../components/Home';
import About from '../../components/About';
// import Comment from '../../components/Comment';
import clsx from 'clsx';
import useWidth from '../../hooks/useWidth';

export default function MainLayout({ className, ...rest }) {
  const width = useWidth();
  const { lang } = useParams();

  return (
    <div className={clsx(lang === 'en' && 'en', className)} {...rest}>
      <Header />
      <Home width={width} />
      <About />
      <Products />
      <Projects />
      {/* <Comment /> */}
      <Contact width={width} />
      <Footer />
    </div>
  );
}
