import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import clsx from 'clsx';
import { useStyles } from './styles';
import AboutContents from '../../contents/AboutContents.json';
import mobileMockup from '../../assets/vectors/mobile-mockup.svg';
import inlabCardVector from '../../assets/vectors/inlab-card.svg';
import enInlabCardVector from '../../assets/vectors/inlab-card-en.svg';

import puzzleIcon from '../../assets/vectors/puzzle-icon.svg';
import searchIcon from '../../assets/vectors/search-icon.svg';
import chartIcon from '../../assets/vectors/chart-icon.svg';
import penIcon from '../../assets/vectors/pen-icon.svg';

const iconList = [puzzleIcon, searchIcon, chartIcon, penIcon];

export default function About() {
  const { lang } = useParams();
  const ltr = lang === 'en';
  const classes = useStyles({ ltr: ltr });

  const ListItem = ({ src, label, info }) => (
    <li className={classes.listItem}>
      <div className={classes.listItemImageWrapper}>
        <img src={src} alt='list item icon' />
      </div>
      <div className={clsx(classes.listItemTextWrapper, !info && 'm-0')}>
        <h5 className={clsx(classes.listItemLabel, !info && 'm-0')}>{label}</h5>
        {info && <p className={classes.listItemInfo}>{info}</p>}
      </div>
    </li>
  );

  return (
    <section id='about' className={classes.root}>
      <Container fluid>
        <Row className={classes.row}>
          <Col xs={12} lg={5}>
            <ul className={classes.list}>
              {AboutContents.list.map((item, index) => (
                <ListItem
                  key={index}
                  src={iconList[index]}
                  label={item.label[lang || 'fa']}
                  info={item.info[lang || 'fa']}
                />
              ))}
            </ul>
          </Col>
          <Col
            xs={12}
            lg={7}
            className={clsx(ltr ? 'text-right' : 'text-left')}
          >
            <div className={classes.mobile}>
              <img src={mobileMockup} alt='mobile' />
            </div>
            <div className={classes.patientCard}>
              <div className='dot-background' />
              <img
                src={lang === 'en' ? enInlabCardVector : inlabCardVector}
                alt='inlab card'
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
